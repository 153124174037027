import React from 'react';
import './Main.scss';

const Main = () => {
    return (
        <div className="Main">
            <h1 className="Main__name">Matthieu VETOIS</h1>
            <h2 className="Main__title">A propos de moi :</h2>
            <p className="Main__desc">
                Fort d'une passion de longue date pour l'informatique et les nouvelles technologies en général, je l'ai choisi pour en faire mon métier.
                Je suis Matthieu, développeur Full Stack, spécialisé dans les langages TypeScript et JavaScript, ainsi que dans les frameworks express pour le back-end et React pour le front-end.
                Grâce à mon parcours à l'école Epitech et à mon expérience pratique dans le domaine, je suis capable de fournir des solutions performantes et personnalisées pour répondre aux besoins de chaque client.
            </p>
        </div>
    );
}

export default Main;
