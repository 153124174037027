import React from 'react';
import "./TimelineCard.scss"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'

export type dataFormation = {
    name: string,
    url?: string,
    logo: string,
    description: string,
    location: string,
    period: string,
    tags: string[],
    graduationTitle?: string,
}

export type dataExperience = {
    name: string,
    url?: string,
    logo: string,
    description: string,
    location: string,
    period: string,
    type: string,
    position: string,
    tags: string[],
}

type props = {
    type: "formations" | "experiences",
    data: dataFormation | dataExperience
}

const TimelineCard = (props: props) => {
    return (
        <div className="TimelineCard">
            <div className="TimelineCard__logo">{props.data.logo && <img src={"./images/" + props.type + "/" + props.data.logo} alt={props.data.name + "_logo"} />}</div>
            <div className="TimelineCard__content">
                <div className="TimelineCard__line">
                    <div className="TimelineCard__title">{props.data.url ? <a href={props.data.url} target="_blank" rel="noopener noreferrer" >{props.data.name} <FontAwesomeIcon icon={faLink} className="TimelineCard__title__icon"/></a>: props.data.name}</div>
                    <div className="TimelineCard__location">{props.data.location}</div>
                </div>
                <div className="TimelineCard__period">{props.data.period}</div>
                {props.type === "formations" && <div className="TimelineCard__graduationTitle">{(props.data as dataFormation).graduationTitle}</div>}
                {props.type === "experiences" && <div className="TimelineCard__position">{(props.data as dataExperience).position} - {(props.data as dataExperience).type}</div>}
                <div className="TimelineCard__description">{props.data.description}</div>
            </div>
        </div>
    )
}

export default TimelineCard;