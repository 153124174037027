import React from "react";
import "./Footer.scss";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faGithub, faStackOverflow } from '@fortawesome/free-brands-svg-icons'
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const Footer = () => {
    const startYear: string = "2020";
    const currentYear: string = new Date().getFullYear().toString();
    return (
        <div className="Footer">
            <div className="Footer__container">
                <div className="Footer__container__item">
                    <h3 className="Footer__container__item__title">Me contacter</h3>
                    <div className="Footer__container__item__list">
                        <div className="Footer__container__item__list__item reverse-txt">rf.siotevm@ueihttam</div>
                    </div>
                </div>
                <div className="Footer__container__item">
                    <h3 className="Footer__container__item__title">Réseaux sociaux</h3>
                    <div className="Footer__container__item__list">
                        <div className="Footer__container__item__list__item">
                            <a href="https://www.linkedin.com/in/matthieu-vetois/" target="_blank" rel="noopener noreferrer" aria-label={"Lien vers mon profil LinkedIn"}><FontAwesomeIcon icon={faLinkedin as IconProp}></FontAwesomeIcon></a>
                        </div>
                        <div className="Footer__container__item__list__item">
                            <a href="https://github.com/mvetois" target="_blank" rel="noopener noreferrer" aria-label={"Lien vers mon profil GitHub"}><FontAwesomeIcon icon={faGithub as IconProp}></FontAwesomeIcon></a>
                        </div>
                        <div className="Footer__container__item__list__item">
                            <a href="https://stackoverflow.com/users/14671536/" target="_blank" rel="noopener noreferrer" aria-label={"Lien vers mon profil StackOverflow"}><FontAwesomeIcon icon={faStackOverflow as IconProp}></FontAwesomeIcon></a>
                        </div>
                    </div>
                </div>
            </div>
            <p className="Footer__copyright">©{startYear === currentYear ? startYear : startYear + "-" + currentYear} Matthieu VETOIS</p>
        </div>
    );
}

export default Footer;