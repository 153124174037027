/*
** Thanks to Théo (https://theomazars.com/) forthe design inspiration
*/

import React from "react";
import "./Skills.scss"

import SkillList from "../../data/skills.json";
import SkillChip from "../SkillChip/SkillChip";

interface ISkillList {
    name: string;
    data: ISkill[];
}

interface ISkill {
    name: string;
    devicon: string;
    color: string;
}

const Skills = () => {
    return (
        <div className="Skills">
            <h1 className="title">Mes Compétences</h1>
            <div className="Skills__container">
                {SkillList.map((skillList: ISkillList, index: number) => (
                    <div className="Skills__container__list" key={"KeySkillList-" + index}>
                    <h2 className="Skills__container__list__title">{skillList.name}</h2>
                    <div className="Skills__container__list__chips">
                        {SkillList[index].data.map((skill: ISkill, skillIndex: number) => (
                            <SkillChip name={skill.name} devicon={skill.devicon} color={skill.color} className="Skills__container__list__chip" key={"KeySkillIndex-" + index + "-" + skillIndex}/>
                        ))}
                    </div>
                </div>
                ))}
            </div>
        </div>
    );
}

export default Skills;