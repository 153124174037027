import React from 'react';
import Timeline from '../Components/Timeline';
import Main from '../Components/Main';
import Skills from '../Components/Skills';
import Footer from '../Components/Footer';

const App = () => {
    return (
        <div className="App">
            <Main />
            <Timeline />
            <Skills />
            <Footer />
        </div>
    );
}

export default App;
