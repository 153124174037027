import React, {useState} from 'react';
import './Time.scss';
import "../style.scss"

import Formations from "../../data/formations.json";
import Experiences from "../../data/experiences.json";

import TimelineCard, {dataFormation, dataExperience} from "../TimelineCard/TimelineCard";
const Timeline = () => {
    const [type, setType] = useState<"formations" | "experiences">("experiences");
    return (
        <div className="Timeline">
            <h1 className="title">Mon parcours</h1>
            <button onClick={() => {setType("experiences")}} className="button">Expériences</button>
            <button onClick={() => {setType("formations")}} className="button">Formations</button>
            {type === "experiences" && Experiences.map((experience: dataExperience, i: number) => {
                return (<TimelineCard type="experiences" data={experience} key={i + experience.name} />)
            })}
            {type === "formations" && Formations.map((formation: dataFormation, i: number) => {
                return (<TimelineCard type="formations" data={formation} key={i + formation.name}/>)
            })}
        </div>
    );
}

export default Timeline;
