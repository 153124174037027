import React from 'react';
import "./SkillChip.scss"

type props = {
    name: string,
    devicon: string,
    color: string,
    className?: string
}

const SkillChip = (props: props) => {
    return (
        <div className={props.className ? "SkillChip " + props.className : "SkillChip"}>
            <div className="SkillChip__icon" style={{ background: props.color }}>
                <i className={props.devicon}/>
            </div>
            <div className="SkillChip__name">
                {props.name}
            </div>
        </div>
    )
}

export default SkillChip;